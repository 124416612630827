$azul-claro: #00A0E9;
$azul-medio: #00A0;
$azul-escuro: #36436E;
$laranja: #FE5409;
$amarelo: #FE5409;
$marrom: #A46F20;
$roxo: #5E358C;

$branco: #fff;
$preto: #000;
$meio-preto: #292929;

$cinza:#F6F6F6;
$cinza2: #A1A1A1;
$cinza3:#696969;
$cinza4: #D9D9D9;

$cinza-claro: #F2F2F2;
$cinza-escuro: #D9D9D9;
$cinza-preto: #A4A4A4;


.texto{
    background-color: rgb(49, 49, 49);

}

.case-card
{
    transition: all 0.8s;
    height: 20rem;
    color: white;

    .case-txt-1
    {
        // opacity: 1;
        position: relative;
        top: 5rem;
        transition: all 0.8s;
    }

    .case-txt-2
    {
        opacity: 0;
        transition: all 0.8s;
    }
    .case-fundo
    {
        background-color: transparent;
        width: 100%;
        height: 100%;
        transition: all 0.8s;
    }
}

.case-card:hover
{
    .case-txt-1
    {
        // opacity: 0;
        top: -10rem;
    }
    .case-txt-2
    {
        opacity: 1;
    }
    .case-fundo
    {
        background-color: #80008080;
    }
}