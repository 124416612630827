.texto {
  background-color: #313131;
}

.case-card {
  transition: all 0.8s;
  height: 20rem;
  color: white;
}
.case-card .case-txt-1 {
  position: relative;
  top: 5rem;
  transition: all 0.8s;
}
.case-card .case-txt-2 {
  opacity: 0;
  transition: all 0.8s;
}
.case-card .case-fundo {
  background-color: transparent;
  width: 100%;
  height: 100%;
  transition: all 0.8s;
}

.case-card:hover .case-txt-1 {
  top: -10rem;
}
.case-card:hover .case-txt-2 {
  opacity: 1;
}
.case-card:hover .case-fundo {
  background-color: #80008080;
}

.cinza {
  background-color: #bebebe;
}

.botao-appstore {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-decoration: none;
  color: #8c8c8c;
  transition-duration: 0.9s;
}

.reset-href {
  text-decoration: none !important;
}

.aplicativo h4 {
  font-family: "mediator" !important;
}

.bloco-cinza {
  background-color: #ececec;
  padding: 1rem;
}
.bloco-cinza h4,
.bloco-cinza p {
  text-decoration: none !important;
  color: black;
}

.btn-blog-mais {
  color: #8c8c8c !important;
}
.btn-blog-mais :hover {
  color: #000 !important;
}

.btn-contato {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-decoration: none;
  color: #fff !important;
  transition-duration: 0.9s;
  background-color: #292929;
  border: none;
  border-radius: 0px;
  padding: 10px 40px;
}
.btn-contato :hover {
  color: #fff !important;
  font-weight: 600;
  transition-duration: 0.9s;
  transform: scale(1.1);
}

#inicio h2 {
  font-family: objective;
  color: #bfbfbf;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
#inicio h3 {
  font-family: mediator;
  color: #000;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 43px;
}
#inicio p {
  font-family: objective;
  color: #696969;
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 24px;
}
#inicio b {
  font-weight: 800;
  font-size: 46px;
  line-height: 49px;
  color: #36436e;
  font-family: mediator;
}
#inicio .btn-sobre {
  background-color: #292929;
  padding: 10px 50px;
  border-radius: none;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  font-family: objective;
  color: #fff;
}
#inicio .contato-cinza {
  background-color: #bebebe;
}
#inicio .contato .cinza {
  padding: 2rem 0;
  background-color: #bebebe;
}
#inicio .contato h4 {
  font-family: mediator;
  color: #000;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
}
#inicio .contato h5 {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
}
#inicio .contato a {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-decoration: none;
  color: #fff !important;
  transition-duration: 0.9s;
  border: none;
  border-radius: 0px;
  padding: 10px 40px;
}

#atuacao {
  background: url(/img/home/back.png) center no-repeat;
  background-size: cover;
  padding: 5rem 0;
}
#atuacao h1 {
  font-family: mediator;
  color: #fff;
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 39px;
}
#atuacao span {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  color: #b1b1b1;
  font-family: objective;
  text-align: justify;
}
#atuacao p {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  color: #b1b1b1;
  font-family: objective;
  text-align: justify;
  transition-duration: 0.9s;
}
#atuacao p:hover {
  font-weight: 400;
  border-bottom: 3px solid #b1b1b1;
  transition-duration: 0.9s;
}

#meio .img-esquerda {
  background: url(/img/home/equipe.png) center no-repeat;
  background-size: cover;
  padding: 13rem 0;
}
#meio .img-esquerda .contato {
  position: absolute;
  bottom: -111rem;
}
#meio .img-esquerda .contato .cinza {
  padding: 2rem 0;
  background-color: #bebebe;
}
#meio .img-esquerda .contato .cinza h4 {
  font-family: mediator !important;
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 39px;
  color: #1e1e1e;
}
#meio .img-esquerda h4 {
  font-family: mediator;
  color: #1e1e1e;
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 39px;
}
#meio .img-esquerda a {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-decoration: none;
  color: #fff !important;
  transition-duration: 0.9s;
  background-color: #292929;
  border: none;
  border-radius: 0px;
  padding: 10px 40px;
}
#meio .img-esquerda a:hover {
  color: #fff !important;
  font-weight: 600;
  transition-duration: 0.9s;
  transform: scale(1.1);
}
#meio h2 {
  font-style: normal;
  font-weight: 800;
  font-size: 35px;
  line-height: 39px;
  color: #1e1e1e;
  font-family: mediator;
}
#meio h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  font-family: objective;
  color: #000;
}
#meio p {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #696969;
  font-family: objective;
}
#meio a {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-decoration: none;
  color: #8c8c8c;
  transition-duration: 0.9s;
}
#meio a .icone-bt {
  color: #8c8c8c;
  font-size: 20px;
  position: relative;
  top: 4px;
  transition-duration: 0.9s;
}
#meio a:hover {
  color: #000;
  font-weight: 600;
  border-bottom: 3px solid #000;
  transition-duration: 0.9s;
}
#meio a:hover .icone-bt {
  color: #000 !important;
  font-weight: 600;
  transition-duration: 0.9s;
}

#revista h4 {
  font-family: mediator;
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 25px;
  color: #1e1e1e;
}

#comentarios h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 39px;
  color: #000;
  font-family: montserrat;
}
#comentarios h1 {
  font-family: mediator;
  color: #1e1e1e;
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 39px;
}

#dentro h1 {
  font-family: mediator;
  color: #1e1e1e;
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 39px;
}
#dentro h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 39px;
  color: #000;
  font-family: montserrat;
}
#dentro h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #696969;
  font-family: montserrat;
}
#dentro a {
  text-decoration: none;
}
#dentro .card-insta-topo .card-insta-icone {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 33rem;
  max-width: 23rem;
}
#dentro .card-insta-topo .card-insta-icone .icone {
  font-size: 0;
}
#dentro .card-insta-topo:hover .card-insta-icone .icone {
  font-size: 4rem;
  color: #fff;
  padding-top: 14rem;
  filter: brightness(100%);
}
#dentro .card-insta-topo:hover .card-insta {
  filter: brightness(50%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
}
#dentro .card-insta {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  min-height: 33rem;
  max-width: 23rem;
  width: 100%;
}
#dentro .card-insta .icone {
  font-size: 0;
}
#dentro .card1 {
  background: url(/img/sobre/mq1.png) center no-repeat;
  background-size: contain;
  border: none;
  min-height: 33rem;
  max-width: 30rem;
  width: 100%;
}
#dentro .card1 .icone {
  font-size: 0;
}
#dentro .card1:hover {
  background: url(/img/sobre/mq1-1.png) center no-repeat;
  background-size: contain;
  border: none;
  min-height: 33rem;
  max-width: 30rem;
}
#dentro .card1:hover .icone {
  font-size: 4rem;
  color: #fff;
  padding-top: 14rem;
}
#dentro .card2 {
  background: url(/img/sobre/mq2.png) center no-repeat;
  background-size: contain;
  border: none;
  min-height: 33rem;
  max-width: 30rem;
}
#dentro .card2 .icone {
  font-size: 0;
}
#dentro .card2:hover {
  background: url(/img/sobre/mq2-1.png) center no-repeat;
  background-size: contain;
  border: none;
  min-height: 33rem;
  max-width: 30rem;
}
#dentro .card2:hover .icone {
  font-size: 4rem;
  color: #fff;
  padding-top: 14rem;
}
#dentro .card3 {
  background: url(/img/sobre/mq3.png) center no-repeat;
  background-size: contain;
  border: none;
  min-height: 33rem;
  max-width: 30rem;
}
#dentro .card3 .icone {
  font-size: 0;
}
#dentro .card3:hover {
  background: url(/img/sobre/mq3-1.png) center no-repeat;
  background-size: contain;
  border: none;
  min-height: 33rem;
  max-width: 30rem;
}
#dentro .card3:hover .icone {
  font-size: 4rem;
  color: #fff;
  padding-top: 14rem;
}
#dentro .card4 {
  background: url(/img/sobre/mq4.png) center no-repeat;
  background-size: contain;
  border: none;
  min-height: 33rem;
  max-width: 30rem;
}
#dentro .card4 .icone {
  font-size: 0;
}
#dentro .card4:hover {
  background: url(/img/sobre/mq4-1.png) center no-repeat;
  background-size: contain;
  border: none;
  min-height: 33rem;
  max-width: 30rem;
}
#dentro .card4:hover .icone {
  font-size: 4rem;
  color: #fff;
  padding-top: 14rem;
}
#dentro .card5 {
  background: url(/img/sobre/mq5.png) center no-repeat;
  background-size: contain;
  border: none;
  min-height: 33rem;
  max-width: 30rem;
}
#dentro .card5 .icone {
  font-size: 0;
}
#dentro .card5:hover {
  background: url(/img/sobre/mq5-1.png) center no-repeat;
  background-size: contain;
  border: none;
  min-height: 33rem;
  max-width: 30rem;
}
#dentro .card5:hover .icone {
  font-size: 4rem;
}
#dentro .card6 {
  background: url(/img/sobre/mq6.png) center no-repeat;
  background-size: contain;
  border: none;
  min-height: 33rem;
  max-width: 30rem;
}
#dentro .card6 .icone {
  font-size: 0;
}
#dentro .card6:hover {
  background: url(/img/sobre/mq6-1.png) center no-repeat;
  background-size: contain;
  border: none;
  min-height: 33rem;
  max-width: 30rem;
}
#dentro .card6:hover .icone {
  font-size: 4rem;
  color: #fff;
  padding-top: 14rem;
}

.owl-prev,
.owl-next {
  position: absolute;
  top: 40%;
  transform: translateY(-60%);
}

.owl-prev {
  left: -2rem;
}

.owl-next {
  right: -2rem;
}

#blog-meio {
  font-family: objective;
  max-height: 500px;
  margin-top: 10rem;
}
#blog-meio h1 {
  font-family: mediator;
  color: #1e1e1e;
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 39px;
}

#blog-meio .container {
  position: relative;
  top: -11rem;
}

#blog-meio .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #f0f0f0;
  background-clip: border-box;
  border: none;
  border-radius: 10px;
}

#blog-meio .imagem-post {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 230px;
  border-radius: 10px 10px 0px 0px;
}

#blog-meio a {
  font-family: objective;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #8c8c8c !important;
}

#blog-meio a {
  position: relative;
  color: #000;
  text-decoration: none;
}

#blog-meio a:hover {
  color: #000;
}

#blog-meio a::before {
  content: "";
  position: absolute;
  display: block;
  width: 90%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

#blog-meio a:hover::before {
  transform: scaleX(1);
}

#blog-meio .icone {
  color: #6d6d6d;
  font-size: 25px;
}

#blog-meio .card:hover {
  box-shadow: 0px 8px 28px 0px #b0b0b0;
  transition-duration: 0.3s;
}

#blog-meio .btn-blog-mais {
  font-family: objective;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #000 !important;
}

#blog-meio .icone-bt {
  font-size: 11px;
  color: #626262;
}

#blog-meio .quadro2 {
  min-height: 500px;
}

#blog-meio .b-texto {
  position: relative;
  left: -30px;
}

#blog-meio h6 {
  font-family: objective;
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 25px;
}

#blog-meio span {
  font-family: objective;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 25px;
  color: #00A0E9;
}

#blog-meio .card h5 {
  font-family: mediator;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 29px;
  color: #000;
}

#blog-meio h1 {
  font-family: mediator;
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 39px;
}

#blog-meio .datas {
  font-family: objective;
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 19px;
  color: #505050;
}

#final {
  background: url(/img/img-footer.png) center no-repeat;
  background-size: cover;
  padding: 5rem 0;
}
#final h2 {
  font-family: mediator;
  font-style: normal;
  font-weight: bold;
  font-size: 46px;
  line-height: 49px;
  color: #fff;
}
#final a {
  background-color: #fff;
  color: #000 !important;
  padding: 15px 50px;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  color: #000;
  font-family: objective;
}

@media (max-width: 1199px) {
  #final .back-final h2 {
    font-size: 35px;
    line-height: 39px;
  }
}
@media (max-width: 1030px) {
  #final .back-final {
    font-size: 17px;
  }
}
@media (max-width: 991px) {
  #final .back-final {
    background-image: url(/img/img-final.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 170px 0px 170px 0px;
  }

  #meio .img-esquerda .contato {
    position: absolute;
    bottom: -166rem;
  }

  .sobre {
    margin-top: 15rem;
  }
}
@media (max-width: 768px) {
  #comentarios h2 {
    font-size: 30px;
    line-height: 39px;
  }

  #final .back-final h2 {
    font-size: 30px;
    line-height: 39px;
  }
}
@media (max-width: 730px) {
  #meio .img-esquerda .contato {
    position: absolute;
    bottom: -111rem;
  }

  .sobre {
    margin-top: 13rem;
  }
}
@media (max-width: 575px) {
  #inicio h3 {
    font-size: 25px;
    line-height: 35px;
  }

  #meio .img-esquerda {
    background: url(/img/home/equipe.png) center no-repeat;
    background-size: cover;
    padding: 7rem 0;
  }

  #meio .img-esquerda .contato {
    position: absolute;
    bottom: -167rem;
  }

  #comentarios h2 {
    font-size: 30px;
    line-height: 39px;
  }

  #equipe h2 {
    font-size: 32px;
  }

  .sobre {
    margin-top: 10rem;
  }
}
@media (max-width: 470px) {
  #meio .img-esquerda .contato {
    position: absolute;
    bottom: -167rem;
  }

  .sobre {
    margin-top: 10rem;
  }
}
@media (max-width: 400px) {
  #meio .img-esquerda .contato {
    position: absolute;
    bottom: -119rem;
  }

  .sobre {
    margin-top: 10rem;
  }
}
@media (max-width: 375px) {
  #meio .img-esquerda .contato {
    position: absolute;
    bottom: -167rem;
  }

  .sobre {
    margin-top: 10rem;
  }
}
@media (max-width: 310px) {
  #meio .img-esquerda .contato {
    position: absolute;
    bottom: -223rem;
  }

  .sobre {
    margin-top: 10rem;
  }
}
@media (max-width: 280px) {
  #meio .img-esquerda .contato {
    position: absolute;
    bottom: -222rem;
  }

  .sobre {
    margin-top: 10rem;
  }
}